<template>
  <el-dialog
    class="box-dialog"
    title="原材料计算"
    :visible.sync="selfShow"
    :close-on-click-modal="false"
  >
    <div class="m-b-10">{{ materialStr }}</div>
    <vxe-grid
      ref="refTable1"
      :size="tableConfig.size"
      :border="tableConfig.border"
      :min-height="tableConfig.minHeight"
      :header-cell-class-name="cellClassName"
      :edit-config="tableConfig.editConfig"
      :row-config="tableConfig.rowConfig"
      :mouse-config="tableConfig.mouseConfig"
      :keyboard-config="tableConfig.keyboardConfig"
      :edit-rules="validRules1"
      :columns="tableColumn1"
      :data="selfData"
    >
      <!-- 原料长度 -->
      <template #edit_material_length="{ row }">
        <el-input v-model="row.material_length" type="number" size="mini" clearable />
      </template>

      <!-- 今日基价 -->
      <template #edit_today_base_price="{ row }">
        <el-input v-model="row.today_base_price" type="number" size="mini" clearable />
      </template>
    </vxe-grid>

    <div class="m-t-20 m-b-10">计算结果</div>
    <vxe-grid
      ref="refTable2"
      class="m-b-20"
      :size="tableConfig.size"
      :border="tableConfig.border"
      :min-height="tableConfig.minHeight"
      :header-cell-class-name="cellClassName"
      :edit-config="tableConfig.editConfig"
      :row-config="tableConfig.rowConfig"
      :mouse-config="tableConfig.mouseConfig"
      :keyboard-config="tableConfig.keyboardConfig"
      :edit-rules="validRules2"
      :columns="tableColumn2"
      :data="selfData"
    >
      <!-- 材料单价 -->
      <template #default_material_price="{ row }">
        <div class="flex flex-y-center flex-x-between">
          <span>{{ row.material_price }}</span>
          <el-link type="primary" :disabled="disabled" @click="toggleLockMaterialPrice(row)">
            {{ row.is_lock_material_price ? '自动计算' : '手动输入' }}
          </el-link>
        </div>
      </template>
      <template #edit_material_price="{ row }">
        <div v-if="row.is_lock_material_price">
          <el-input v-model="row.material_price" type="number" size="mini" clearable />
        </div>
        <div v-else class="flex flex-y-center flex-x-between">
          <span>{{ row.material_price }}</span>
          <el-link type="primary" :disabled="disabled" @click="toggleLockMaterialPrice(row)">
            {{ row.is_lock_material_price ? '自动计算' : '手动输入' }}
          </el-link>
        </div>
      </template>

      <!-- 所需材料数量 -->
      <template #default_material_counts="{ row }">
        <div class="flex flex-y-center flex-x-between">
          <span>{{ row.material_counts }}</span>
          <el-link type="primary" :disabled="disabled" @click="toggleLockMaterialCounts(row)">
            {{ row.is_lock_material_counts ? '自动计算' : '手动输入' }}
          </el-link>
        </div>
      </template>
      <template #edit_material_counts="{ row }">
        <div v-if="row.is_lock_material_counts">
          <el-input v-model="row.material_counts" type="number" size="mini" clearable />
        </div>
        <div v-else class="flex flex-y-center flex-x-between">
          <span>{{ row.material_counts }}</span>
          <el-link type="primary" :disabled="disabled" @click="toggleLockMaterialCounts(row)">
            {{ row.is_lock_material_counts ? '自动计算' : '手动输入' }}
          </el-link>
        </div>
      </template>
    </vxe-grid>

    <ItemTestRule :isMaterial="true" :data.sync="selfData[0]" :disabled="disabled" />

    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import ItemTestRule from './item-test-rule'
import math from './math'
export default {
  components: {
    ItemTestRule
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selfShow: false,
      validRules1: {
        // today_base_price: [{ required: true }]
      },
      validRules2: {
        // material_price: [{ required: true }],
        // material_counts: [{ required: true }]
      },
      tableColumn1: [
        {
          title: '原料长度(mm)',
          field: 'material_length',
          slots: { edit: 'edit_material_length' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '尾料长度(mm)',
          field: 'tailing_length'
        },
        {
          title: '今日基价(元/kg)',
          field: 'today_base_price',
          slots: { edit: 'edit_today_base_price' },
          editRender: { autofocus: '.el-input__inner' }
        }
      ],
      tableColumn2: [
        {
          title: '支重(kg)',
          field: 'single_weight'
        },
        {
          title: '材料单价(元)',
          slots: { default: 'default_material_price', edit: 'edit_material_price' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '每支可切数量(件)',
          field: 'parts_count'
        },
        {
          title: '每件材料成本(元)',
          field: 'cost_price_unit'
        },
        {
          title: '所需材料数量',
          slots: { default: 'default_material_counts', edit: 'edit_material_counts' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '所需材料成本(元)',
          field: 'material_sales_amount'
        }
      ],
      selfData: []
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let disabled = this.disabled
      let obj = {
        rowConfig: {
          isHover: false,
          isCurrent: false
        },
        editConfig: {
          beforeEditMethod() {
            return !disabled
          }
        },
        keyboardConfig: {
          isEdit: !disabled
        }
      }
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    },
    // 原材料字符
    materialStr() {
      let result = []
      if (this.data.craft) result.push(this.data.craft)
      if (this.data.texture) result.push(this.data.texture)
      if (this.data.sales_specification) result.push(this.data.sales_specification)
      return result.join('-')
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    data: {
      handler(val) {
        if (JSON.stringify(this.selfData) !== JSON.stringify(val)) {
          this.selfData = [this.$util.deepCopy(val)]
        }
      },
      deep: true,
      immediate: true
    },
    selfData: {
      handler(val) {
        if (JSON.stringify(this.data) !== JSON.stringify(val)) {
          let row = val[0]
          math.totalRowMaterialData(row) // 计算-汇总行材料
          math.totalRowSaleData(row) // 计算-汇总行销售
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.selfShow = this.show
  },
  methods: {
    // 表格单元格样式设置
    cellClassName({ column }) {
      if (column.slots && column.slots.edit) return 'edit'

      return null
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.popupCancel()
      if (JSON.stringify(this.data) !== JSON.stringify(this.selfData[0])) {
        this.$emit('update:data', this.$util.deepCopy(this.selfData[0]))
      }
    },
    // 更新-材料单价
    toggleLockMaterialPrice(row) {
      row.is_lock_material_price = !row.is_lock_material_price
    },
    // 更新-所需材料数量
    toggleLockMaterialCounts(row) {
      row.is_lock_material_counts = !row.is_lock_material_counts
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
}
</style>
